import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Link } from "gatsby-theme-material-ui";
import * as React from "react";
import { Helmet } from "react-helmet";
import Blockquote from "src/components/Blockquote";
import BorderedList from "src/components/BorderedList";
import Container from "src/components/Container";
import ImageHeaderUnit from "src/components/ImageHeaderUnit";
import Layout from "src/components/Layout";
import Overline from "src/components/Overline";
import SubHeader from "src/components/SubHeader";
import aboutUsImg from "src/images/about-us/about-us.jpg";
import charlesImg from "src/images/about-us/Charles Osuji.jpg";
import mikeImg from "src/images/about-us/Mike Keller.jpg";
import sandraImg from "src/images/about-us/Sandra Williams.jpg";
import carmenImg from "src/images/about-us/Carmen Hidalgo.jpg";
// import wendyImg from "src/images/about-us/wendy.jpg";
import shariImg from "src/images/about-us/Shari Wooldridge.jpg";
import logo from "src/images/logo.svg";
import ellenImg from "src/images/about-us/Ellen Hurtado.jpg";
import emmanuelImg from "src/images/about-us/Emmanuel Carrie.jpg";
import myeekaImg from "src/images/about-us/Myeeka Calhoun.jpg";
import anitaImg from "src/images/about-us/Anita Roberts.jpg";

const TEAM = [
  {
    title: "Interim Executive Director",
    name: "Shari Wooldridge",
    img: shariImg,
  },
  // {
  //   title: "Executive Director",
  //   name: "Wendy U Jackson",
  //   img: wendyImg,
  // },
  { name: "Mike Keller", title: "Director of Quality Assurance", img: mikeImg },
  {
    name: "Sandra Williams",
    title: "Human Resources/Operations Manager",
    img: sandraImg,
  },
  {
    name: "Anita Roberts",
    title: "Finance Director",
    img: anitaImg
  },
  { name: "Charles Osuji", title: "Controller", img: charlesImg },
  {
    name: "Ellen Hurtado",
    title: "Director of Programs at Crossroads",
    img: ellenImg,
  },
  { name: "Danishia Davis", title: "Lead Case Manager for Realignment" },
  {
    name: "Myeeka Calhoun",
    title: "Case Management Services Manager",
    img: myeekaImg,
  },
  { name: "Marcelo Jaime", title: "Coordinated Entry Services Manager" },
  { name: "Carmen Hidalgo", title: "Family Services Manager", img: carmenImg },
  {
    name: "Emmanuel Carrie",
    title: "Family Matters Shelter Manager  ",
    img: emmanuelImg,
  },
  {
    name: "Sonya Perry",
    title: "SSVF Program Manager",
  },
];

const BOARD = [
  {
    name: "Kevin Bremond",
    role: "Board Chair",
    affiliation: "Program Administrator, First 5 Alameda County",
  },
  {
    name: "Aisha Brown",
    role: "Board Member",
    affiliation:
      "Principal Government and Community Relations, Bay Area Rapid Transit (BART)",
  },
  {
    name: "Linda Gardner",
    role: "Board Vice Chair",
    affiliation:
      "Former Director, Alameda County Housing and Community Development (Retired)",
  },
  {
    name: "Janice Granby",
    role: "Board Secretary",
    affiliation: "ILS Program Coordinator, Stepping Stones Growth Center",
  },
  {
    name: "Angela B. Jenkins",
    role: "Board Member",
    affiliation: "Vice President, Accountable Communities, Prisma Health",
  },
  { name: "Emily M. Lo", role: "Board Treasurer" },
];

const PARTNERS = [
  {
    title: "Government Partners",
    names: [
      "Alameda Health System",
      "Alameda County Behavioral Healthcare Services",
      "Alameda County First 5, Resilience Fund Grant",
      "Alameda County Health Care Services Agency, Coordinated Entry Housing Navigation",
      "Alameda County Housing and Community Development, HOPWA",
      "Alameda County Housing and Community Development, Housing Support Program",
      "Alameda County Housing and Community Development, Realignment Program ",
      "Alameda County Social Services Agency, Emergency Shelter and Services",
      "Alameda County Social Services Agency, Family Rapid Rehousing Program",
      "City of Oakland, Community Development Block Grant",
      "City of Oakland, Department of Human Services, PATH",
      "City of Oakland, Department of Human Services, HUD- Supportive Housing Program",
      "City of Oakland, Department of Human Services, Measure Q",
      "United Way of the Bay Area Emergency Food and Shelter Program Local Board, FEMA",
      "Veterans Administration, Healthcare for Homeless Veterans – Residential Services",
      "Veterans Administration, Supportive Services for Veteran Families ",
    ],
  },
  {
    title: "Foundations/Private Partners",
    names: [
      "AmazonSmile Foundation",
      "Bright Funds",
      "Day 1 Families Fund",
      "Delta Dental Community Care Foundation",
      "Dylan Jackson Community Focus",
      "East Bay Community Foundation",
      "Fidelity Charitable Gift Fund",
      "Evelyn and Walter Haas, Jr. Fund",
      "Holmgren Charitable Lead Trust",
      "J.P. Morgan Chase Foundation",
      "Morgan Stanley Gift Fund",
      "Patelco Credit Union",
      "Ruth and Peter Metz Family Foundation",
      "Sunlight Giving Foundation",
      "The Blackbaud Giving Fund",
      "The Cohen Living Trust",
      "The San Francisco Foundation",
    ],
  },
  {
    title: "Business Partners",
    names: [
      "Bay Security",
      "Cheiron",
      "Imperial Star Empire",
      "One Toyota of Oakland",
      "Sysco Food Services of San Francisco",
      "Target",
    ],
  },
  {
    title: "Faith-Based Partners",
    names: ["Christian Bible Fellowship Church", "Skyline Community Church"],
  },
];

export default function AboutUsPage() {
  return (
    <Layout>
      <Helmet>
        <title>About Us</title>
      </Helmet>
      <ImageHeaderUnit
        subtitle="About Us"
        title="Our Mission, Team, and Partners"
        img={aboutUsImg}
        backgroundPosition={"50% 25% "}
      />
      <Container>
        <SubHeader>Our mission</SubHeader>
      </Container>
      <Blockquote sx={{ mt: { sm: 0 }, mx: { sm: 3 } }}>
        EOCP empowers individuals and families who are homeless in Alameda
        County to regain a life of self-reliance. We provide dignified emergency
        and transitional housing and compassionate, comprehensive support
        services that prepare homeless people to successfully transition to
        well-being and sustainable permanent housing.
      </Blockquote>

      <Container sx={{ pt: 1 }}>
        <SubHeader id="staff">Our people</SubHeader>
        <Overline>Team Leadership</Overline>
        <BorderedList
          items={TEAM.map(({ img, name, title }) => {
            return (
              <Box
                key={name}
                sx={{
                  display: "flex",
                }}
                py={{ xs: 3, sm: 1 }}
              >
                <Box pr={2}>
                  {img ? (
                    <Box
                      component="img"
                      src={img}
                      alt={name}
                      sx={{
                        width: 75,
                        height: 75,
                        objectFit: "cover",
                        borderRadius: 100,
                      }}
                    />
                  ) : (
                    <Box
                      sx={{
                        width: 75,
                        height: 75,
                        borderRadius: 100,
                        backgroundColor: (theme) => theme.palette.grey[300],
                        backgroundImage: `url(${logo})`,
                        backgroundPosition: "center center",
                        backgroundSize: "70%",
                        backgroundRepeat: "no-repeat",
                        opacity: 0.7,
                      }}
                    >
                      {/* <Box img src={logo} /> */}
                    </Box>
                  )}
                </Box>

                <Box>
                  <Typography
                    color="textSecondary"
                    variant="subtitle"
                    fontWeight="bold"
                    display="block"
                  >
                    {name}
                  </Typography>
                  <Typography
                    color="textSecondary"
                    variant="caption"
                    display="block"
                  >
                    {title}
                  </Typography>
                </Box>
              </Box>
            );
          })}
        />
        <Box py={4}>
          <Typography>
            <b>Interested in joining EOCP’s team?</b>{" "}
            <Link to="/work-with-us">Learn about our open positions.</Link>
          </Typography>
        </Box>
      </Container>

      <Container sx={{ py: 3 }}>
        <Overline id="board">Board of Directors</Overline>
        <BorderedList
          items={BOARD.map(({ name, role, affiliation }) => {
            return (
              <Box
                key={name}
                sx={{
                  display: "flex",
                }}
                py={{ xs: 3, sm: 1 }}
              >
                <Box>
                  <Typography
                    color="textSecondary"
                    variant="subtitle"
                    fontWeight="bold"
                    display="block"
                  >
                    {name}
                  </Typography>
                  <Typography
                    color="textSecondary"
                    variant="caption"
                    display="block"
                  >
                    <b>{role}</b>
                    <br />
                    {affiliation}
                  </Typography>
                </Box>
              </Box>
            );
          })}
        />
      </Container>

      <Blockquote
        id="history"
        overline="How EOCP Started"
        sx={{ mt: { sm: 0 }, mx: { sm: 3 } }}
      >
        East Oakland Community Project’s story begins as a grassroots support
        network that evolved into a sustainable community resource. In 1990, a
        group of community activists began the Mission Safe program in a vacant
        lot on East 14th Street. They provided street-level support — food,
        motel vouchers, and service referrals — to people experiencing
        homelessness. At the time, thousands of people had recently been
        displaced from their homes due to the 1989 Loma Prieta earthquake,
        adding to the already existing crisis of minimal shelter for people
        experiencing homelessness in East Oakland. In response, the City of
        Oakland, Alameda County, and Mission Safe joined together to establish
        an emergency housing shelter that became known as the East Oakland
        Community Project.
      </Blockquote>

      <Container sx={{ py: 3 }}>
        <SubHeader id="supporters" sx={{ pb: 1 }}>
          Our partners
        </SubHeader>
        <Grid container spacing={4}>
          {PARTNERS.map(({ names, title }) => {
            return (
              <Grid item xs={12} sm={6} key={title} sx={{ mb: 2 }}>
                <Overline>{title}</Overline>
                {names.map((name) => (
                  <Typography sx={{ pb: 0.5 }} variant="body2" key={name}>
                    {name}
                  </Typography>
                ))}
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </Layout>
  );
}
